<template>
  <div :class="msgType">
    {{ msg }}
  </div>
</template>

<script>
export default {
  name: "InfoBlockMsg",
  props: {
    msg: String,
    msgType: {
      type: String,
      default: 'info_msg'
    }
  },
  data() {
    return {
      initLength: 10,
      curTimer: 0,
      myTimer: 0,
    }
  },
  methods: {
    processTime() {
      this.curTimer--;
      if (this.curTimer === 0) {
        clearInterval(this.myTimer);
        this.$store.commit('removeMsg');
      }
    }

  },

    created() {
      this.curTimer = this.initLength;
      this.myTimer = setInterval(this.processTime, 1000);
    }

}
</script>

<style scoped>

</style>