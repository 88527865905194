import axios from "axios";
import store from "@/store";
import router from "@/routes";


export default async function gPost(baseUrl, par, suc, fail, apiToken = store.state.apiToken) {
    let param = {
        ...par,
        shopId: store.state.shopId
    }
    return axios.post(baseUrl, param, {
        validateStatus: function () {
            return true;
        },
        headers: {
            // 'Authorization': apiToken,
            'HZ-API': apiToken,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Hz-User-Token': store.state.userToken
        }
    }).then(response => {
            if (response.status === 403) {
                router.push({name: 'Home'})
                setTimeout(() => {
                        store.dispatch('clearUser');
                        store.commit('setNewMsg', {msg: "Neplatná autorizace k provádění úprav", msgType: "alert_msg"});
                    },
                    500
                )
            } else if (response.status < 300 && response.status >= 200) {
                suc(response.data);
            } else {
                fail(response);
            }
        }
    ).catch((response) => {
            response.msg = "Nelze komunikovat se serverem. Chyba spojení."
            fail(response);
        }
    )
}